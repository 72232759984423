/* src/components/Cards/OutcomeCard.module.css */

.cardContainer {
    width: 90%; /* Ocupa el 90% del ancho de la página */
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
 
    text-align: left;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #0A5364; /* Color anaranjado para el título */
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }
  
  .checkmark {
    color: #0A5364; /* Color de los checkmarks */
    margin-right: 8px;
  }
  
  .outcomeList {
    list-style-type: none; /* Elimina las viñetas predeterminadas */
    padding-left: 0;
    color: #333333;
    line-height: 1.6;
  }
  
  .outcomeItem {
    font-size: 16px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  