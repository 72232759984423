/* Centra el contenedor principal en la página */
.cardContainer {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center;     /* Centra verticalmente */
    height: auto;           /* Asegura que ocupe todo el alto de la pantalla */
    padding: 20px;           /* Ajusta el padding si es necesario */
    margin: 0;               /* Elimina el margen de la página */
    width: 100%;             /* Asegura que ocupe todo el ancho de la pantalla */
}

/* Estilos de la tarjeta */
.card {
    display: flex;
    border-radius: 8px;
    margin: 20px;
    padding: 20px;
    background-color: #ffffff;
    width: 90%;        /* Ajusta el ancho según lo necesites */
    max-width: 1300px; /* Añadí un límite máximo de ancho para mantener la carta manejable */
    flex-wrap: wrap;   /* Asegura que los elementos se ajusten bien cuando no haya suficiente espacio */
}

/* Resto de las clases permanecen igual */
.textContainer {
    flex: 1;
}

.title {
    color: #0A5364;
    font-size: 24px;
    font-weight: bold;
}

.subtitle {
    color: #0B5465;
    font-size: 20px;
    margin-top: 10px;
}

.list {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
}

.listItem {
    margin-bottom: 15px;
}

.sectionTitle {
    color: #80B1B7;
    font-size: 18px;
}

.description {
    color: #000;
    font-size: 16px;
}

.info {
    color: #000;
    font-size: 16px;
}

.imageContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    width: 70%;
    height: 95%;
    border-radius: 8px;
}

/* Oculta la imagen en dispositivos con ancho máximo de 768px */
@media (max-width: 768px) {
  .imageContainer {
    display: none;  /* No se mostrará la imagen en pantallas pequeñas */
  }
}
