.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #0A5364;
    padding-top: 20px;
    z-index: 1050;
    /* Quitar transform y transición */
    transform: none;
    transition: none;
}

.sidebar .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: #fff;
    background: none;
    border: none;
    cursor: pointer;
}

.sidebar .nav-link {
    color: #fff;
    padding: 10px 20px;
    font-size: 18px;
    text-align: left;
}

.navbar {
    position: fixed; /* Fija el navbar */
    top: 0; /* Asegura que esté en la parte superior */
    left: 0;
    width: 100%; /* Asegura que ocupe todo el ancho */
    z-index: 1030; /* Se coloca por encima del contenido */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Agrega una sombra opcional */
    margin-bottom: 10px;
}


.navbar {
    background-color: #0A5364 !important;
}

.logo {
    display: inline-block;
}

.navLink {
    color: #ffffff !important;
    font-size: 18px;
    padding: 10px 15px;
    text-decoration: none;
    font-weight: normal;
    transition: color 0.2s;
}

.navLink:hover {
    color: #D6AF44 !important; /* Color amarillo al pasar el mouse */
    font-weight: bold; /* Negrita al pasar el mouse */
}

.activeNavLink {
    color: #D6AF44 !important; /* Color amarillo para el enlace activo */
    font-weight: bold; /* Negrita para el enlace activo */
}

.navButton {
    display: inline-flex;
    align-items: center;
}

.accountButton {
    font-size: 14px;
    padding: 5px 10px;
    color: #ffffff;
}

.connectButton {
    font-size: 14px;
    padding: 5px 10px;
    color: #ffffff;
}

.logoutButton{
    background-color: #ff9100;
    border-color: #fff;
}