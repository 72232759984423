.cartaContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.carta {
  position: relative;
  width: 280px;
  padding: 12px;
  background: linear-gradient(135deg,rgb(177, 177, 177), #e1f3fa, rgb(151, 151, 151));
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  transition: transform 0.2s, background 0.5s;
  overflow: hidden;
}

.carta:hover {
  position: relative;
  width: 280px;
  padding: 12px;
  background: linear-gradient(135deg,rgb(168, 168, 168), rgb(47, 77, 83) , rgb(161, 161, 161));
  border: 2px solid rgba(231, 238, 238, 0.507);
  box-shadow: 0 0 15px rgb(129, 148, 148);
  border-radius: 12px;
  color: #000;
  text-align: center;
  transition: 0.3s;
}

.carta::before {
  content: '';
  position: absolute;
  top: -100%;
  left: -150%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.2);
  transform: rotate(45deg);
  pointer-events: none;
  transition: 1s;

}

.mintButton {
  display: inline-block;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: transparent;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 12px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
}

.mintButton:hover {
  background-color: rgb(233, 210, 0);
  color: black;
  box-shadow: 0 0 10px rgb(206, 209, 196), 0 0 20px rgba(151, 151, 151, 0.616);
}


@keyframes shine {
  0% {
    top: -150%;
    left: -150%;
  }
  50% {
    top: 50%;
    left: 50%;
  }
  100% {
    top: 150%;
    left: 150%;
  }
}

.cartaImg {
  width: 100%;
  border-radius: 8px;
}




