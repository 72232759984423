.resourceCard {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    width: 95%;
  }
  
  .animatedTitle {
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
    animation: slideForward 1s ease-in-out forwards;
  }
  
  @keyframes slideForward {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .resourceCard p {
    font-size: 1em;
    color: #555;
    line-height: 1.6;
    margin-top: 5px;
  }
  