.cardContainer {
  width: 90%;            
  max-width:100%;      
  margin: 40px auto;     /* 40px arriba y abajo, centrado horizontal */
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  text-align: center;
}


.title {
  font-size: 24px;
  font-weight: bold;
  color: #0b5465; /* Color de título */
  margin-bottom: 15px;
  text-align: left; /* Alinea el título a la izquierda */
}

.objectivesList {
  list-style-type: disc;  /* Viñetas de lista */
  padding-left: 20px;
  color: #000;
  line-height: 1.6;
  text-align: left;       /* Alinea la lista a la izquierda */
}

.objectiveItem {
  font-size: 16px;
  margin-bottom: 10px;    /* Espacio inferior entre elementos */
  margin-top: 10px;       /* Espacio superior entre elementos */
  text-align: left;       /* Alinea cada elemento de la lista a la izquierda */
}

/* Ajustes responsivos para pantallas pequeñas (ej: móvil) */
@media (max-width: 768px) {
  .cardContainer {
    width: 95%;           /* Un poco más de ancho disponible en móvil */
    margin-top:10%;    /* Margen superior e inferior de 20px */
    padding: 10px;        /* Reduce el padding en móvil */
  }

  .title {
    font-size: 20px;      /* Ajuste del tamaño de fuente en dispositivos pequeños */
  }

  .objectiveItem {
    font-size: 14px;      /* Ajusta la fuente para mayor legibilidad en móvil */
  }
}
