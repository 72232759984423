/* roadmap.module.css */
.roadmap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background-color: #ffffff;
    color: #000;
  }
  
  .roadmapStep {
    display: flex;
    align-items: center;
    width: 95%;
    border: 2px solid;
    border-radius: 10px;
    padding: 10px;
    transition: transform 0.3s ease;
  }
  
  .roadmapStep:hover {
    transform: scale(1.05);
  }
  
  .roadmapIcon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #ffffff;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .roadmapContent h2 {
    margin: 0;
    font-size: 18px;
  }
  
  .roadmapContent p {
    margin: 0;
    color: #b0b0b0;
  }
  