.footer {
  background-color: #0A5364;
  color: white;
  padding: 20px 0;
}

.footer-logo {
  width: 120px;
}

.footer-partners img {
  width: 80px;
  margin: 0 10px;
  cursor: pointer;
}

.footer h6 {
  font-weight: bold;
  margin-bottom: 15px;
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer a:hover {
  color: #ffd700;
}

.footer .text-end p {
  margin: 10px 0;
}

.footer .text-end a {
  margin: 0 5px;
  font-size: 14px;
}
