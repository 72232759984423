.custom-button {
    border: 4px solid #80B1B7; /* Borde azul turquesa */
    color: #80B1B7; /* Texto del mismo color del borde */
    background-color: transparent; /* Fondo transparente */
    font-weight: bold;
    border-radius: 20px; /* Bordes redondeados */
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  
  /* Tamaños dinámicos */
  .custom-button.small {
    padding: 5px 10px;
    font-size: 14px;
  }
  
  .custom-button.medium {
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .custom-button.large {
    padding: 14px 28px;
    font-size: 18px;
  }
  
  /* Hover: Cambia el fondo y el color del texto */
  .custom-button:hover {
    background-color: #80B1B7;
    color: white;
  }
  
  /* Active: Mantiene los cambios al hacer clic */
  .custom-button:active {
    background-color: #80B1B7;
    color: white;
  }
  