.cardContainer {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  color: rgb(0, 0, 0);
  margin-bottom: 20px;
  justify-content: space-between;
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  gap: 20px;
}

.imageLeft {
  flex-direction: row-reverse;
}

.imageRight {
  flex-direction: row;
}

.image {
  width: 25%; /* Ajusta el ancho de la imagen en escritorio */
  height: auto; /* Mantiene la proporción de la imagen */
  object-fit: cover; /* Asegura que la imagen se ajuste dentro del área sin recortarse */
  object-position: center; /* Centra la imagen dentro de su contenedor */
  border-radius: 8px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* Efecto de resaltado cuando el usuario hace hover en la imagen */
.image:hover {
  transform: scale(1.05); /* Aumenta el tamaño ligeramente */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3); /* Añade una sombra alrededor */
}

.textContainer {
  flex: 1;
  width: 70%;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #0b5465;
  margin-bottom: 10px;
  position: relative;
}

.subtitle {
  display: block;
  background-color: #0A5364;
  padding: 2px 6px;
  color: white;
  font-weight: bold;
  margin-top: 5px;
}

.description {
  font-size: 16px;
  line-height: 1.6;
  color: #333333;
  text-align: justify;
}

/* 
  Estilos responsivos: 
  - Para pantallas de un ancho máximo de 768px (tablets y móviles), 
    la tarjeta se apila en columna y la imagen toma todo el ancho
*/
@media (max-width: 768px) {
  .cardContainer {
    flex-direction: column;
    align-items: center; /* Centra los elementos en columna */
  }

  /* Forzamos la imagen a ocupar el 100% del ancho y mantener height auto */
  .image {
    width: 100%;
    height: auto;
  }

  .textContainer {
    width: 100%;
    margin-top: 20px;
    text-align: center; /* Centra el texto en pantallas pequeñas */
  }

  .title {
    text-align: center;
  }
}
