.pageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff; /* Fondo de la página */
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columnas fijas */
  gap: 20px;
  background-color: #fff; /* Fondo oscuro */
  padding: 20px;
  border-radius: 10px;
  color: #0A5364; /* Color de texto blanco */
  width: 90%;
}

.featureItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.icon {
  font-size: 2.rem; /* Iconos más grandes */
  margin-right: 15px;
}

.title {
  font-size: 1.2rem; /* Texto más grande */
  font-weight: bold;
  color: #0A5364; /* Color de texto blanco */
}

/* Centra y ajusta el contenedor principal */
.pageContainer {
  max-width: 1200px;   /* Ancho máximo */
  margin: 0 auto;      /* Centrar horizontalmente */
  padding: 2rem;       /* Espaciado interno */
}

/* Grid para las tarjetas */
.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 1.5rem;
}

/* Ajuste para pantallas medianas (tablets) */
@media (max-width: 992px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Ajuste para pantallas pequeñas (móviles) */
@media (max-width: 576px) {
  .gridContainer {
    grid-template-columns: 1fr;
  }
}

.featureItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc; /* Opcional: Borde */
  border-radius: 8px;     /* Opcional: Esquinas redondeadas */
  background-color: #fff; /* Opcional: Fondo */
  padding: 1rem;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

/* Efecto hover */
.featureItem:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.icon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.title {
  font-weight: bold;
  font-size: 1.1rem;
  text-align: center;
}
